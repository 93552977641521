import {Dictionary} from '@jscrpt/common';
import {Configuration, config as cfg} from 'app-config';

import defaultConfig from '../config/config.json';

/**
 * Overrides config
 * @param override Configuration that is used for overriding
 */
function overrideConfig(override: Configuration)
{
    const isPresent = function isPresent(obj: any): boolean
    {
        return obj !== undefined && obj !== null;
    };

    if(isPresent(override?.configuration?.debug))
    {
        let debug: boolean = override?.configuration?.debug;

        if(typeof override.configuration.debug == 'string')
        {
            debug = (<string>override.configuration.debug).toLowerCase() == 'true';
        }

        cfg.configuration.debug = debug;
    }

    if(isPresent(override?.configuration?.keycloak?.maxInactiveIntervalInSec))
    {
        let maxInactiveIntervalInSec: number = override?.configuration?.keycloak?.maxInactiveIntervalInSec;

        if(typeof override.configuration.keycloak?.maxInactiveIntervalInSec == 'string')
        {
            maxInactiveIntervalInSec = +override.configuration.keycloak?.maxInactiveIntervalInSec;
        }

        cfg.configuration.keycloak.maxInactiveIntervalInSec = maxInactiveIntervalInSec;
    }

    if(isPresent(override?.configuration?.keycloak?.pingIntervalInSec))
    {
        let pingIntervalInSec: number = override?.configuration?.keycloak?.pingIntervalInSec;

        if(typeof override.configuration.keycloak?.pingIntervalInSec == 'string')
        {
            pingIntervalInSec = +override.configuration.keycloak?.pingIntervalInSec;
        }

        cfg.configuration.keycloak.pingIntervalInSec = pingIntervalInSec;
    }

    if(isPresent(override?.configuration?.keycloak?.oauthServerUrl))
    {
        cfg.configuration.keycloak.oauthServerUrl = override.configuration.keycloak?.oauthServerUrl;
    }

    if(isPresent(override?.configuration?.keycloak?.keycloakRealm))
    {
        cfg.configuration.keycloak.keycloakRealm = override.configuration.keycloak?.keycloakRealm;
    }

    if(isPresent(override?.configuration?.keycloak?.keycloakClient))
    {
        cfg.configuration.keycloak.keycloakClient = override.configuration.keycloak?.keycloakClient;
    }

    if(isPresent(override?.configuration?.keycloak?.redirectUri))
    {
        cfg.configuration.keycloak.redirectUri = override.configuration.keycloak?.redirectUri;
    }

    if(isPresent(override?.configuration?.keycloak?.useGoldman))
    {
        let useGoldman: boolean = override?.configuration?.keycloak?.useGoldman;

        if(typeof override?.configuration?.keycloak?.useGoldman == 'string')
        {
            useGoldman = (<string>override?.configuration?.keycloak?.useGoldman).toLowerCase() == 'true';
        }

        cfg.configuration.keycloak.useGoldman = useGoldman;
    }

    if(isPresent(override?.configuration?.portalBaseUrl))
    {
        cfg.configuration.portalBaseUrl = override.configuration.portalBaseUrl;
    }

    if(isPresent(override?.configuration?.imiBaseUrl))
    {
        cfg.configuration.imiBaseUrl = override.configuration.imiBaseUrl;
    }

    if(isPresent(override?.configuration?.queryBaseUrl))
    {
        cfg.configuration.queryBaseUrl = override.configuration.queryBaseUrl;
    }

    if(isPresent(override?.configuration?.goldmanBaseUrl))
    {
        cfg.configuration.goldmanBaseUrl = override.configuration.goldmanBaseUrl;
    }

    if(isPresent(override?.configuration?.goldmanKeycloak))
    {
        cfg.configuration.goldmanKeycloak = override.configuration.goldmanKeycloak;
    }

    if(isPresent(override?.general?.theme))
    {
        cfg.general.theme = override.general.theme;
    }

    if(isPresent(override?.general?.language))
    {
        cfg.general.language = override.general.language;
    }
}

/**
 * Loads default config
 */
export function loadDefaultConfig(): void
{
    Object.keys(defaultConfig).forEach(key =>
    {
        (cfg as unknown as Dictionary)[key] = (defaultConfig as Dictionary)[key];
    });
}

/**
 * Function used for loading configuration
 */
export async function loadConfig(): Promise<void>
{
    const loadJson = async (path: string) =>
    {
        const response = await fetch(new Request(path));

        return await response.json();
    };
    
    loadDefaultConfig();

    try
    {
        //default configuration
        const config: Configuration = await loadJson('local/config');
    
        Object.keys(config).forEach(key =>
        {
            (cfg as unknown as Dictionary)[key] = (defaultConfig as Dictionary)[key];
            (cfg as unknown as Dictionary)[key] = (config as unknown as Dictionary)[key];
        });
    }
    catch(e)
    {
        console.log('failed to load default configuration');
    }

    try
    {
        //config override from env variables
        const configOverride = await loadJson('local/configEnv');
    
        overrideConfig(configOverride);
    }
    catch(e)
    {
        console.log('failed to load environment configuration');
    }
}